<template>
  <div class="navBox">
    <div class="flex al-center wrap">
      <div v-for="item in iptData" :key="item.id" class="m-r1 m-b1">
        <div v-if="item.type == 'cascader'">
          <a-cascader  :getPopupContainer="(triggerNode) => { return triggerNode.parentNode;}" v-model:value="fromOption[item.prop]" :options="item.opt" :placeholder="item.placeholder" />
        </div>
         <div v-if="item.type == 'selectTime'">
          <a-date-picker :style="{ width: item.width + 'px'}" v-model:value="fromOption[item.prop]"  :placeholder="item.placeholder" />
         </div>
        <div v-if="item.type == 'select'">
          <a-select
            ref="select"
            allowClear
            v-model:value="fromOption[item.prop]"
            :style="{ width: item.width + 'px'}"
            :placeholder="item.placeholder" item.opt
          >
          <a-select-option v-for="opt in item.opt" :key="opt.id" :value="opt.id">{{ opt.name }}</a-select-option>
          </a-select>
        </div>
        <div v-if="item.type == 'selectRelevance'" class="flex">
          <a-select
            ref="select"
            v-model:value="fromOption[item.prop1]"
            :style="{ width: item.width + 'px'}"
            :placeholder="item.placeholder1" 
            @change="seleAdd1(fromOption[item.prop1], item)" 
          >
          <a-select-option v-for="opt in item.opt1" :key="opt.id" :value="opt.label">{{ opt.label }}</a-select-option>
          </a-select>
          <div class="m-l1" v-if="item.opt2.length > 0" >
            <a-select
            ref="select"
            v-model:value="fromOption[item.prop2]"
            :style="{ width: item.width + 'px'}"
            :placeholder="item.placeholder2" 
          >
          <a-select-option v-for="opt in item.opt2" :key="opt.id" :value="opt.label">{{ opt.label }}</a-select-option>
          </a-select>
          </div>
        </div>
        <div v-if="item.type == 'input'">
          <a-input :style="{ width: item.width + 'px'}" v-model:value="fromOption[item.prop]" allowClear :placeholder="item.placeholder" />
        </div>
      </div>
      <div class="btnBox m-b1">
      <a-button type="primary" class="m-r1" @click="searchBtn">搜索</a-button>
      <a-button @click="resetBtn">重置</a-button>
    </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref,watch } from "vue";
const props = defineProps({
  iptData: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits(['searchAdd','resetBtn']) //搜索、重置事件
const fromOption = ref({})
const setValue = () => {
    let option = {};
    props.iptData.map((item) => {
      if (item.prop1) {
      option[item.prop1] = item.value1;
    }
    if (item.prop2) {
      option[item.prop2] = item.value2;
    } else{
      option[item.prop] = item.value;
    }
      
    });
    fromOption.value =option
}
//搜索
const searchBtn = () => {
  emit('searchAdd',fromOption.value)
}
//第一项选择
const seleAdd1 = (val,item) => {
  fromOption.value[item.prop2] = null;
  emit('seleAdd1',val)
}
// 重置
const resetBtn = () => {
  setValue()
  emit('resetBtn')
}
watch(() => props.iptData.map(item => item.value),() => {
  setValue()
})
//时间比较清除
const resetTime = () => {
  fromOption.value.startTime = null
  fromOption.value.endTime = null
}
defineExpose({
  resetTime,
})
onMounted(() => {
  setValue()
})
</script>

<style lang="less" scoped>
.navBox {
  padding: 20px 10px;
  background: #fff;
}
:deep(.ant-cascader-menu){
  &::-webkit-scrollbar {
    width: 6px; /* 控制滚动条的宽度 */
  }
  &::-webkit-scrollbar-thumb {
    background: #f6f6f6; /* 修改滚动条滑块颜色 */
    border-radius: 10px;
    height: 8px;
  }
}
</style>
